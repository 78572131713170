import React from 'react';
import PropTypes from 'prop-types';

import MonthlyPayment from '@components/containers/MonthlyPayment';

import numberFormat from '@utils/numberFormat';
import useGetInsurance from '@utils/useGetInsurance';

const InsuranceMonthlyPaymentCard = ({ insuranceId }) => {
  const { insurance, loading } = useGetInsurance(insuranceId);

  const title = 'Försäkringspremie';

  const bankAccountIdPayin = insurance && insurance.bankAccountIdPayin;
  const hasPassedInvoicesPayed = insurance && insurance.passedInvoicesPayed;
  const hasPaymentPlan = false;

  const nextPaymentAmount = insurance ? numberFormat(insurance.monthlyPayment, 2, { unit: 'kr' }) : '';

  return (
    <MonthlyPayment
      loading={loading}
      title={title}
      bankAccountIdPayin={bankAccountIdPayin}
      hasPassedInvoicesPayed={hasPassedInvoicesPayed}
      hasPaymentPlan={hasPaymentPlan}
      nextPaymentAmount={nextPaymentAmount}
    />
  );
};

InsuranceMonthlyPaymentCard.propTypes = {
  insuranceId: PropTypes.string.isRequired,
};

export default InsuranceMonthlyPaymentCard;
