import React from 'react';
import PropTypes from 'prop-types';

import getUrl from '@utils/getUrl';

import urls from '@constants/urls';

import InsuranceDetailsCard from '@components/modules/InsuranceDetailsCard';
import InsuranceMonthlyPaymentCard from '@components/modules/InsuranceMonthlyPaymentCard';
import InsuranceAgreements from '@components/modules/InsuranceAgreements';
import InsuranceLastTransactions from '@components/modules/InsuranceLastTransactions';

import LoggedPage from '@components/containers/LoggedPage';

const InsuranceView = ({ insuranceId }) => (
  <LoggedPage
    title="Låneskydd"
    previousUrl={getUrl(urls.HOME.MY_INSURANCES)}
  >
    <InsuranceDetailsCard insuranceId={insuranceId} />
    <InsuranceMonthlyPaymentCard insuranceId={insuranceId} />
    <InsuranceLastTransactions insuranceId={insuranceId} />
    <InsuranceAgreements insuranceId={insuranceId} />
  </LoggedPage>
);

InsuranceView.propTypes = {
  insuranceId: PropTypes.string.isRequired,
};

export default InsuranceView;
