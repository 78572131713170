import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useGetInsurance from '@utils/useGetInsurance';

import { downloadAccountAgreement, downloadPublicDocument } from '@redux/actions/documents';
import products from '@constants/products';
import AccountAgreement from '../containers/AccountAgreement';

const InsuranceAgreements = ({ insuranceId }) => {
  const documents = [];

  const { loading } = useGetInsurance(insuranceId);

  const dispatch = useDispatch();

  const handleDownloadPolicyLetter = useCallback((e) => {
    e.preventDefault();
    dispatch(downloadAccountAgreement(insuranceId));
  }, [insuranceId, dispatch]);

  const handleDownloadInsuranceTerms = useCallback((e) => {
    e.preventDefault();
    dispatch(downloadPublicDocument(products.INSURANCE_LOAN_PROTECTION, 'terms'));
  }, [dispatch]);

  const handleDownloadIPID = useCallback((e) => {
    e.preventDefault();
    dispatch(downloadPublicDocument(products.INSURANCE_LOAN_PROTECTION, 'ipid'));
  }, [dispatch]);

  documents.push({
    title: <>Försäkrings&shy;villkor</>,
    onClick: handleDownloadInsuranceTerms,
  });
  documents.push({
    title: <>IPID och förköps&shy;information</>,
    onClick: handleDownloadIPID,
  });
  documents.push({
    title: <>Försäkrings&shy;besked</>,
    onClick: handleDownloadPolicyLetter,
  });

  return (
    <AccountAgreement
      loading={loading}
      documents={documents}
    />
  );
};

InsuranceAgreements.propTypes = {
  insuranceId: PropTypes.string.isRequired,
};

export default InsuranceAgreements;
