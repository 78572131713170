import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AccountDetailsCard from '@components/containers/AccountDetailsCard';

import { IsActiveBadge } from '@components/elements/Badge';

import useGetInsurance from '@utils/useGetInsurance';

import numberFormat from '@utils/numberFormat';

import validators from '@utils/validators';

const { isDefined } = validators.generics;

const InsuranceDetailsCard = ({ insuranceId }) => {
  const [isActive, setIsActive] = useState(undefined);

  const { insurance, loading } = useGetInsurance(insuranceId);

  const mainAmountValue = insurance ? numberFormat(insurance.terms.amount, 2, { unit: 'kr' }) : '';

  const publicId = `Försäkringsnummer: ${insurance ? insurance.publicId : ''}`;

  const activeAccountStatus = useSelector(
    ({ settings }) => settings.constants.accountStatuses.ACTIVE,
  );

  useEffect(() => {
    if (!isDefined(activeAccountStatus) || !isDefined(insurance)) return;
    setIsActive(insurance.status === activeAccountStatus);
  }, [activeAccountStatus, insurance]);

  const accountDetails = [];
  accountDetails.push({
    title: 'Premie',
    value: insurance && insurance.terms ? '6.5%' : '',
  });
  accountDetails.push({
    title: 'Premiebelopp',
    value: insurance && insurance.terms ? numberFormat(insurance.terms.subscriptionAmount, 2, { unit: 'kr' }) : '',
  });
  // accountDetails.push({
  //   title: 'Försäkringen täcker',
  //   value: insurance && insurance.loanPublicId,
  // });

  return (
    <AccountDetailsCard
      title="Låneskydd"
      loading={loading}
      mainAmountTitle="Försäkrat belopp"
      mainAmountValue={mainAmountValue}
      badge={<IsActiveBadge isActive={isActive} />}
      publicId={publicId}
      accountDetails={accountDetails}
    />
  );
};

InsuranceDetailsCard.propTypes = {
  insuranceId: PropTypes.string.isRequired,
};

export default InsuranceDetailsCard;
