import React from 'react';
import PropTypes from 'prop-types';

import useGetInsurance from '@utils/useGetInsurance';
import urls from '@constants/urls';
import getUrl from '@utils/getUrl';
import LastTransactions from '@components/containers/LastTransactions';

const InsuranceLastTransactions = ({ insuranceId }) => {
  const { insurance, loading } = useGetInsurance(insuranceId);

  const transactions = (insurance && insurance.transactions) || [];

  const viewMoreurl = getUrl(urls.HOME.MY_INSURANCES.TRANSACTIONS, { insuranceId });

  return (
    <LastTransactions
      loading={loading}
      transactions={transactions}
      viewMoreurl={viewMoreurl}
    />
  );
};

InsuranceLastTransactions.propTypes = {
  insuranceId: PropTypes.string.isRequired,
};

export default InsuranceLastTransactions;
